import { render, staticRenderFns } from "./strain-form-control.vue?vue&type=template&id=5dd0e19a&scoped=true&"
import script from "./strain-form-control.vue?vue&type=script&setup=true&lang=ts&"
export * from "./strain-form-control.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./strain-form-control.vue?vue&type=style&index=0&id=5dd0e19a&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dd0e19a",
  null
  
)

export default component.exports