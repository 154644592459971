import { isNavigationFailure, Location, RawLocation, Route, RouteMeta } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { hasAccessToPermission, useAuthenticationStore } from '@/application/authentication/store';
import { isFeatureEnabled, redirectRouteDependingOnAuthentication } from '@/application/authentication/helper';
import { AuthenticatedUser } from '@/application/authentication/types';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import { useMaintenanceModeStore } from '@/application/maintenance-overlay/store';
import { Feature, UserRole } from '@/types';
import { useDashboardStore } from '@/private/rider/dashboard/store';
import { usePinboardStore } from '@/private/rider/pinboard/store';
import { TokenStorage } from '@/application/authentication/token-storage';

function getRedirectRouteForUnauthenticatedUser(): Location {
  return { name: 'root' };
}

function getInheritedAccessibleRolesByRole(role: UserRole): UserRole[] {
  switch (role) {
    case UserRole.ROLE_ADMIN:
      return [
        UserRole.ROLE_ADMIN,
      ];
    case UserRole.ROLE_FARM_MANAGER:
      return [
        UserRole.ROLE_ADMIN,
        UserRole.ROLE_FARM_MANAGER,
      ];
    case UserRole.ROLE_USER:
      return [
        UserRole.ROLE_ADMIN,
        UserRole.ROLE_FARM_MANAGER,
        UserRole.ROLE_USER,
      ];
    default:
      return [];
  }
}

export async function accessGuard(
  to: Route,
  from: Route,
  next: (to?: RawLocation | false | ((vm: any) => any) | void) => void
) {
  if (!to.meta) {
    throw Error('The route meta is undefined');
  }

  const authenticationStore = useAuthenticationStore();
  if (!authenticationStore.wasInitialAuthenticationAttempted) {
    // We don't want to show an error, as the initial request might fail when not authorized
    await authenticationStore.getInitialAuthentication()
      .then(() => {
        if (!authenticationStore.isAdmin) {
          useDashboardStore().getNotificationStatus()
            .catch(() => {});
          useMyStableStore().getAvailableHorses()
            .catch(() => {});
          if (isFeatureEnabled(Feature.SURVEYS)
            && !TokenStorage.isImpersonatedUser()
          ) {
            usePinboardStore().getUnansweredSurveysShownOnAppStart()
              .catch(() => {});
          }
        }
      })
      .catch((error) => {
        // Init maintenance mode explicitly, as the interceptor is not yet initialized
        if (error.response?.status === 503) {
          useMaintenanceModeStore().enableMaintenanceMode()
            .catch(() => {});
        }
      });
  }

  const redirectLocation = nextPotentialRedirectLocation(
    to.meta,
    authenticationStore.user
  );
  if (redirectLocation === null) {
    next();
  } else {
    try {
      next(redirectLocation);
    } catch (error: any) {
      // Catch all navigation errors, as otherwise Sentry is spammed with errors
      if (!isNavigationFailure(error)) {
        throw error;
      }
    }
  }
}

export function nextPotentialRedirectLocation(
  meta: RouteMeta,
  user: AuthenticatedUser | null
): Location | null {
  // Route is always accessible
  if (meta.accessibleFor === RouteAccessibility.ALWAYS) {
    return null;
  }

  const isAuthenticated = user !== null;

  // User is authenticated but route is just accessible as unauthenticated user
  if (isAuthenticated
    && meta.accessibleFor === RouteAccessibility.UNAUTHENTICATED
  ) {
    return redirectRouteDependingOnAuthentication();
  }

  // User is unauthenticated but route is just accessible as authenticated user
  if (!isAuthenticated
    && meta.accessibleFor === RouteAccessibility.AUTHENTICATED
  ) {
    return getRedirectRouteForUnauthenticatedUser();
  }

  if (isAuthenticated
    && user.role === UserRole.ROLE_ADMIN
    && meta.requiresRole !== UserRole.ROLE_ADMIN
  ) {
    return redirectRouteDependingOnAuthentication();
  }

  // If user role is required to access the route
  if (meta.requiresRole) {
    if (!isAuthenticated) {
      return getRedirectRouteForUnauthenticatedUser();
    }

    const accessibleRoles = getInheritedAccessibleRolesByRole(meta.requiresRole);
    const hasUserRole = accessibleRoles.filter((accessibleRole) => user.role === accessibleRole).length > 0;

    // If user doesn't have required role and is authenticated
    if (!hasUserRole && isAuthenticated) {
      return redirectRouteDependingOnAuthentication();
    }

    // If user doesn't have required role and is unauthenticated
    if (!hasUserRole && !isAuthenticated) {
      return getRedirectRouteForUnauthenticatedUser();
    }
  }

  // If feature is required to access the route
  if (meta.requiresFeature) {
    if (!isAuthenticated) {
      return getRedirectRouteForUnauthenticatedUser();
    }

    if (!user.farm) {
      return redirectRouteDependingOnAuthentication();
    }

    const hasUserAccessToFeature = isFeatureEnabled(meta.requiresFeature);
    if (!hasUserAccessToFeature) {
      return redirectRouteDependingOnAuthentication();
    }
  }

  // If permission is required to access the route
  if (meta.requiresPermission) {
    if (!isAuthenticated) {
      return getRedirectRouteForUnauthenticatedUser();
    }

    if (!user.farm) {
      return redirectRouteDependingOnAuthentication();
    }

    const hasUserAccessToPermission = user.enabledPermissions
      ? hasAccessToPermission(user.enabledPermissions, meta.requiresPermission)
      : false;

    if (!hasUserAccessToPermission) {
      return redirectRouteDependingOnAuthentication();
    }
  }

  return null;
}
