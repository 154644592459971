import { render, staticRenderFns } from "./farm-service-multi-autocomplete-form-control.vue?vue&type=template&id=2e8c7118&scoped=true&"
import script from "./farm-service-multi-autocomplete-form-control.vue?vue&type=script&setup=true&lang=ts&"
export * from "./farm-service-multi-autocomplete-form-control.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./farm-service-multi-autocomplete-form-control.vue?vue&type=style&index=0&id=2e8c7118&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e8c7118",
  null
  
)

export default component.exports