import { Location } from 'vue-router';
import { AuthenticationInLocalStorage, authenticationStateVersion, localStorageKey, useAuthenticationStore } from '@/application/authentication/store';
import { FarmManagerPermission, Feature, FeatureVisibleForRiders } from '@/types';
import { setLocalStorageItem } from '@/helpers/local-storage-helper';
import { objectToJSON } from '@/infrastructure/helper';

export function addLocalStorageSyncSubscriptionForAuthentication(): void {
  const authenticationStore = useAuthenticationStore();

  authenticationStore.$subscribe(() => {
    if (authenticationStore.wasInitialAuthenticationAttempted
      && authenticationStore.user
    ) {
      const authentication: AuthenticationInLocalStorage = {
        version: authenticationStateVersion,
        wasInitialAuthenticationAttempted: authenticationStore.wasInitialAuthenticationAttempted,
        user: authenticationStore.user,
      };
      setLocalStorageItem(
        localStorageKey,
        objectToJSON(authentication)
      );
    }
  });
}

export function isFeatureEnabled(feature: Feature): boolean {
  return useAuthenticationStore().user?.farm?.enabledFeatures.includes(feature) ?? false;
}

export function isFeatureVisibleForRiders(featureVisibleForRiders: FeatureVisibleForRiders): boolean {
  return useAuthenticationStore().user?.farm?.featuresVisibleForRiders.includes(featureVisibleForRiders) ?? false;
}

export function isBookingCalendarsVisible(): boolean {
  const authenticationStore = useAuthenticationStore();

  return authenticationStore.isAuthenticated
    && !authenticationStore.isAdmin
    && (
      (
        (isFeatureEnabled(Feature.FACILITY_RESERVATIONS)
          || isFeatureEnabled(Feature.RIDING_LESSONS)
        ) && authenticationStore.facilities.length > 0
      )
      || (
        isFeatureEnabled(Feature.RIDING_LESSONS)
        && authenticationStore.user!.farm!.isRidingLessonPlanVisible
      )
    );
}

export function isPinboardVisible(): boolean {
  const authenticationStore = useAuthenticationStore();

  return authenticationStore.isAuthenticated
    && !authenticationStore.isAdmin
    && (
      isFeatureVisibleForRiders(FeatureVisibleForRiders.NEWS)
      || (
        isFeatureEnabled(Feature.EVENTS)
        && isFeatureVisibleForRiders(FeatureVisibleForRiders.EVENTS)
      )
    );
}

export function doesAuthenticatedUserHavePermission(permission: FarmManagerPermission): boolean {
  return useAuthenticationStore().doesAuthenticatedUserHavePermission(permission);
}

export function redirectRouteDependingOnAuthentication(): Location {
  const authenticationStore = useAuthenticationStore();

  if (!authenticationStore.isAuthenticated) {
    return { name: 'login' };
  }

  if (authenticationStore.isAdmin) {
    return { name: 'user-management-global-user-management' };
  }

  if (authenticationStore.isFarmManager) {
    return { name: 'control-panel' };
  }

  return { name: 'dashboard' };
}
