import { RouteConfig, RouteMeta } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import InvoicingTabs from './components/invoicing-tabs.vue';
import Invoicing from './components/invoicing.vue';
import InvoicingConfigurationLoadingWrapper from './components/invoicing-configuration-loading-wrapper.vue';

const defaultMeta: RouteMeta = {
  title: 'Rechnungen',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresFeature: Feature.INVOICING,
  requiresPermission: FarmManagerPermission.INVOICING_READ,
};

export const invoicingRoutes: RouteConfig[] = [
  {
    path: 'rechnungen',
    component: InvoicingTabs,
    children: [
      {
        name: 'invoicing-management',
        path: '',
        redirect: { name: 'invoicing-invoicing' },
      },
      {
        name: 'invoicing-invoicing',
        path: 'rechnungen',
        component: Invoicing,
        meta: defaultMeta,
      },
      {
        name: 'invoicing-configuration',
        path: 'konfiguration',
        component: InvoicingConfigurationLoadingWrapper,
        meta: defaultMeta,
      },
    ],
  },
];
