import { Price } from '@/types';

export function convertDecimalToCurrency(currency: number): number {
  const cents = currency * 100;

  return Math.round(cents);
}

export function parsePercentageWithOneDecimalPlace(number: number): number {
  const percentPerThousand = number * 10;

  return Math.floor(percentPerThousand);
}

export function calculateTaxAmount(grossSellingPriceInCents: number, taxRatePerThousand: number): number {
  // 1000 = 10,00€
  const grossSellingPrice = grossSellingPriceInCents / 100;

  // 190 = 119%
  const percentageIncludingTaxes = (100 + (taxRatePerThousand / 10));

  // 190 = 19%
  const taxRate = taxRatePerThousand / 10;

  const taxAmount = grossSellingPrice / percentageIncludingTaxes * taxRate * 100;

  // Taxes have to be rounded up and full amount is then pulled from gross selling price
  let taxAmountInCents = Math.trunc(taxAmount);

  const netSellingPriceInCents = grossSellingPriceInCents - taxAmountInCents;

  const grossSellingPriceInCentsCalculated = Math.ceil(netSellingPriceInCents * (1 + taxRatePerThousand / 1000));

  // If the calculation differs, we need to add the "missing" cent to the tax amount
  if (grossSellingPriceInCentsCalculated > grossSellingPriceInCents) {
    ++taxAmountInCents;
  }

  return taxAmountInCents;
}

export function calculateTotalPrice(
  price: Price,
  amount: number
): Price {
  // When working with an amount, we use commercial rounding (Kaufmännisches Runden).
  const grossSellingPriceInCents = Math.round(price.grossSellingPrice * amount);
  const taxAmountInCents = calculateTaxAmount(grossSellingPriceInCents, price.taxRate);
  const netSellingPriceInCents = grossSellingPriceInCents - taxAmountInCents;

  return {
    grossSellingPrice: grossSellingPriceInCents,
    netSellingPrice: netSellingPriceInCents,
    taxRate: price.taxRate,
    taxAmount: taxAmountInCents,
  };
}

export function calculatePrice(
  grossSellingPriceInCents: number,
  taxRatePerThousand: number
): Price {
  const taxAmountInCents = calculateTaxAmount(grossSellingPriceInCents, taxRatePerThousand);
  const netSellingPriceInCents = grossSellingPriceInCents - taxAmountInCents;

  return {
    grossSellingPrice: grossSellingPriceInCents,
    netSellingPrice: netSellingPriceInCents,
    taxRate: taxRatePerThousand,
    taxAmount: taxAmountInCents,
  };
}
