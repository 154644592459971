import { render, staticRenderFns } from "./options-form-control.vue?vue&type=template&id=26740aa8&scoped=true&"
import script from "./options-form-control.vue?vue&type=script&setup=true&lang=ts&"
export * from "./options-form-control.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./options-form-control.vue?vue&type=style&index=0&id=26740aa8&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26740aa8",
  null
  
)

export default component.exports