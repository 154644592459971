import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest, performApiRequestForFile } from '@/infrastructure/api-requests';
import { FileResponse } from '@/application/types';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { DefineFarmBankInformationAsManagerCommand, DefineFarmTermsOfPaymentAsManagerCommand, ConfigureInvoicingAsManagerCommand, GetInvoicePDFAsManagerQuery, GetInvoicesAsZIPForMonthAsManagerQuery, GetInvoicesForMonthAsManagerQuery, Invoice, UpdateFarmAddressAsManagerCommand, UpdateFarmBankInformationAsManagerCommand, UpdateFarmLegalInformationAsManagerCommand, UpdateFarmTermsOfPaymentAsManagerCommand, UpdateAccountingEmailAddressAsManagerCommand, InvoiceConfigurationResponse, PersonWithSpecialBookingsNotYetInvoiced, OrganizationWithSpecialBookingsNotYetInvoiced, CreateManualInvoiceForPersonAsManagerCommand, CreateManualInvoiceForOrganizationAsManagerCommand } from './types';

// -- Queries

export function getInvoiceConfiguration(query: FarmAndUserProperties): Promise<InvoiceConfigurationResponse> {
  const url = `${apiUrl}/api/invoicing/get-invoice-configuration-as-manager-query`;
  return performApiRequest<InvoiceConfigurationResponse>({ url, method: 'POST', data: query });
}

export function getInvoicesForMonth(query: GetInvoicesForMonthAsManagerQuery & FarmAndUserProperties): Promise<Invoice[]> {
  const url = `${apiUrl}/api/invoicing/get-invoices-for-month-as-manager-query`;
  return performApiRequest<Invoice[]>({ url, method: 'POST', data: query });
}

export function getInvoicePDF(query: GetInvoicePDFAsManagerQuery & FarmAndUserProperties): Promise<FileResponse> {
  const url = `${apiUrl}/api/invoicing/get-invoice-pdf-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

export function getInvoicesAsZIPForMonth(query: GetInvoicesAsZIPForMonthAsManagerQuery & FarmAndUserProperties): Promise<FileResponse> {
  const url = `${apiUrl}/api/invoicing/get-invoices-as-zip-for-month-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

export function getPersonsWithSpecialBookingsNotYetInvoiced(
  query: FarmAndUserProperties
): Promise<PersonWithSpecialBookingsNotYetInvoiced[]> {
  const url = `${apiUrl}/api/invoicing/get-persons-with-special-bookings-not-yet-invoiced-as-manager-query`;
  return performApiRequest<PersonWithSpecialBookingsNotYetInvoiced[]>({ url, method: 'POST', data: query });
}

export function getOrganizationsWithSpecialBookingsNotYetInvoiced(
  query: FarmAndUserProperties
): Promise<OrganizationWithSpecialBookingsNotYetInvoiced[]> {
  const url = `${apiUrl}/api/invoicing/get-organizations-with-special-bookings-not-yet-invoiced-as-manager-query`;
  return performApiRequest<OrganizationWithSpecialBookingsNotYetInvoiced[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function configureInvoicing(command: ConfigureInvoicingAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/define-invoice-information-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmAddress(command: UpdateFarmAddressAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/update-farm-address-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmLegalInformation(command: UpdateFarmLegalInformationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/update-farm-legal-information-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function defineFarmBankInformation(command: DefineFarmBankInformationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/define-farm-bank-information-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmBankInformation(command: UpdateFarmBankInformationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/update-farm-bank-information-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function removeFarmBankInformation(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/remove-farm-bank-information-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function defineFarmTermsOfPayment(command: DefineFarmTermsOfPaymentAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/define-farm-terms-of-payment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmTermsOfPayment(command: UpdateFarmTermsOfPaymentAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/update-farm-terms-of-payment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function removeFarmTermsOfPayment(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/remove-farm-terms-of-payment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateAccountingEmailAddress(command: UpdateAccountingEmailAddressAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/update-accounting-email-address-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function grantUsersAccessToInvoices(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/grant-users-access-to-invoices-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function denyUsersAccessToInvoices(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/deny-users-access-to-invoices-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createManualInvoiceForPerson(command: CreateManualInvoiceForPersonAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/create-manual-invoice-for-person-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createManualInvoiceForOrganization(
  command: CreateManualInvoiceForOrganizationAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/invoicing/create-manual-invoice-for-organization-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
