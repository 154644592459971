import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CSV } from '@/types';
import { AddConcentratedFeedTypeAsManagerCommand, CreateCreditNoteAsManagerCommand, CreateCreditNotesAsManagerWorkflow, CreateCustomBookingAsManagerCommand, CreateCustomBookingServiceAsManagerCommand, CreateCustomBookingsAsManagerWorkflow, CreditNote, CustomBooking, GetCreditNotesAsManagerQuery, GetCustomBookingsAsManagerQuery, GetSettlementsForMonthAsCSVAsManagerQuery, GetSettlementsForMonthAsManagerQuery, LedgerConfiguration, PersonForFilter, SettlementsForMonth, UpdateCreditNotePaymentMethodAsManagerCommand, UpdateCustomBookingPaymentMethodAsManagerCommand, CustomBookingService, UpdateNameOfCustomBookingServiceAsManagerCommand, UpdatePriceOfCustomBookingServiceAsManagerCommand, UpdateIdentificationForAccountingOfCustomBookingServiceAsManagerCommand, ArchiveCustomBookingServiceAsManagerCommand, GetCustomBookingServicesAsManagerQuery, AssignCustomBookingServiceAsManagerCommand, AssignCustomBookingServiceToPersonsAsManagerWorkflow, UpdateCustomBookingFilterConfigurationAsManagerCommand, CustomBookingFilterConfiguration, UpdateDefaultInvoiceCreationTypeOfCreditNotesAsManagerCommand, UpdateDefaultInvoiceCreationTypeOfCustomBookingsAsManagerCommand } from './types';

// -- Queries

export function getConfiguration(query: FarmAndUserProperties): Promise<LedgerConfiguration> {
  const url = `${apiUrl}/api/ledger/get-configuration-as-manager-query`;
  return performApiRequest<LedgerConfiguration>({ url, method: 'POST', data: query });
}

export function getCustomBookings(query: GetCustomBookingsAsManagerQuery & FarmAndUserProperties): Promise<CustomBooking[]> {
  const url = `${apiUrl}/api/ledger/get-custom-bookings-as-manager-query`;
  return performApiRequest<CustomBooking[]>({ url, method: 'POST', data: query });
}

export function getCreditNotes(query: GetCreditNotesAsManagerQuery & FarmAndUserProperties): Promise<CreditNote[]> {
  const url = `${apiUrl}/api/ledger/get-credit-notes-as-manager-query`;
  return performApiRequest<CreditNote[]>({ url, method: 'POST', data: query });
}

export function getSettlementsForMonth(query: GetSettlementsForMonthAsManagerQuery & FarmAndUserProperties): Promise<SettlementsForMonth> {
  const url = `${apiUrl}/api/ledger/get-settlements-for-month-as-manager-query`;
  return performApiRequest<SettlementsForMonth>({ url, method: 'POST', data: query });
}

export function getSettlementsForMonthAsCSV(query: GetSettlementsForMonthAsCSVAsManagerQuery & FarmAndUserProperties): Promise<CSV> {
  const url = `${apiUrl}/api/ledger/get-settlements-for-month-as-csv-as-manager-query`;
  return performApiRequest<CSV>({ url, method: 'POST', data: query });
}

export function getPersons(query: FarmAndUserProperties): Promise<PersonForFilter[]> {
  const url = `${apiUrl}/api/ledger/get-persons-as-manager-query`;
  return performApiRequest<PersonForFilter[]>({ url, method: 'POST', data: query });
}

export function getCustomBookingServices(
  query: GetCustomBookingServicesAsManagerQuery & FarmAndUserProperties
): Promise<CustomBookingService[]> {
  const url = `${apiUrl}/api/ledger/get-custom-booking-services-as-manager-query`;
  return performApiRequest<CustomBookingService[]>({ url, method: 'POST', data: query });
}

export function getCustomBookingFilterConfiguration(query: FarmAndUserProperties): Promise<CustomBookingFilterConfiguration> {
  const url = `${apiUrl}/api/ledger/get-custom-booking-filter-configuration-as-manager-query`;
  return performApiRequest<CustomBookingFilterConfiguration>({ url, method: 'POST', data: query });
}

// -- Commands

// Custom bookings

export function createCustomBooking(command: CreateCustomBookingAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/create-custom-booking-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createCustomBookings(command: CreateCustomBookingsAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/create-custom-bookings-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateCustomBookingPaymentMethod(
  command: UpdateCustomBookingPaymentMethodAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-custom-booking-payment-method-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateDefaultInvoiceCreationTypeOfCustomBookings(
  command: UpdateDefaultInvoiceCreationTypeOfCustomBookingsAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-default-invoice-creation-type-of-custom-bookings-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

// Credit notes

export function createCreditNote(command: CreateCreditNoteAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/create-credit-note-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createCreditNotes(command: CreateCreditNotesAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/create-credit-notes-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateCreditNotePaymentMethod(
  command: UpdateCreditNotePaymentMethodAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-credit-note-payment-method-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateDefaultInvoiceCreationTypeOfCreditNotes(
  command: UpdateDefaultInvoiceCreationTypeOfCreditNotesAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-default-invoice-creation-type-of-credit-notes-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

// Gerblhof feeding

export function addConcentratedFeedType(command: AddConcentratedFeedTypeAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/add-concentrated-feed-type-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

// Custom booking services

export function createCustomBookingService(command: CreateCustomBookingServiceAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/create-custom-booking-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateNameOfCustomBookingService(
  command: UpdateNameOfCustomBookingServiceAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-name-of-custom-booking-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updatePriceOfCustomBookingService(
  command: UpdatePriceOfCustomBookingServiceAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-price-of-custom-booking-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateIdentificationForAccountingOfCustomBookingService(
  command: UpdateIdentificationForAccountingOfCustomBookingServiceAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-identification-for-accounting-of-custom-booking-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function archiveCustomBookingService(command: ArchiveCustomBookingServiceAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/archive-custom-booking-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function assignCustomBookingService(command: AssignCustomBookingServiceAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/assign-custom-booking-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function assignCustomBookingServiceToPersons(
  command: AssignCustomBookingServiceToPersonsAsManagerWorkflow & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/assign-custom-booking-service-to-persons-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateCustomBookingFilterConfiguration(
  command: UpdateCustomBookingFilterConfigurationAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-custom-booking-filter-configuration-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
