import { isMoment, momentWithoutTimezone } from '@/helpers';
import { Date, Moment, Time } from '@/types';

// Regular expression to pre-check if strings contain an ISO-8601 date.
// Based on http://dotat.at/tmp/ISO_8601-2004_E.pdf, implementation from https://stackoverflow.com/a/3143231
// eslint-disable-next-line max-len
const dateTimeRegex = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;
// Regular expression to pre-check if strings contain an DIN-5008 date.
// const dayRegex = /^\d{4}-\d{2}-\d{2}$/;

export function objectToJSON(object: object): string {
  return JSON.stringify(object, momentAndDateAndTimeReplacer);
}

export function dataFromJSON<T = unknown>(data: string): T {
  return JSON.parse(data, momentAndDateAndTimeReviver);
}

export function momentAndDateAndTimeReplacer(this: any, key: string, value: unknown): string | unknown {
  if (isMoment(this[key])) {
    return (this[key] as Moment).toISOString();
  }

  if (Date.isDate(this[key])) {
    return (this[key] as Date).date;
  }

  if (Time.isTime(this[key])) {
    return (this[key] as Time).format();
  }

  return value;
}

function isDateTimeString(value: unknown): boolean {
  // Check if string contains ISO-8601 or DIN-5008 date before parsing to avoid warnings.
  return typeof value === 'string'
    && dateTimeRegex.test(value);
}

function isDateString(value: unknown): boolean {
  return typeof value === 'string'
    && momentWithoutTimezone(value, 'YYYY-MM-DD', true).isValid();
}

export function momentAndDateAndTimeReviver(_: string, value: unknown): Moment | unknown {
  if (isDateTimeString(value)) {
    return momentWithoutTimezone
      .utc(value as string)
      .tz('Europe/Berlin');
  }

  if (isDateString(value)) {
    return new Date(value as string);
  }

  if (Time.isValidTimeString(value)) {
    return Time.fromString(value as string);
  }

  return value;
}
