import { render, staticRenderFns } from "./info-popup.vue?vue&type=template&id=5a155dce&"
import script from "./info-popup.vue?vue&type=script&setup=true&lang=ts&"
export * from "./info-popup.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./info-popup.vue?vue&type=style&index=0&id=5a155dce&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports