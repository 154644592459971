import { canBeParsedAsNumber, convertStringToNumber, countDecimals } from '@/helpers';
import { formatNumber } from '@/helpers/stateful-format';
import { InternalValueRule } from './types';

export function maxLengthRule(maxLength: number, message: string): InternalValueRule<string> {
  return (value) => value.trim().length <= maxLength
    ? true
    : message;
}

export function csvCompliantRule(message: string): InternalValueRule<string> {
  return (value) => value === ''
    || !value.match('[,;"\']')
    ? true
    : message;
}

export function numberStringRule(message = 'Der Wert muss eine gültige Zahl sein'): InternalValueRule<string> {
  return (value) => {
    if (!value) {
      return true;
    }

    return canBeParsedAsNumber(value)
      ? true
      : message;
  };
}

export function minNumberStringRule(min: number, customMessage?: string): InternalValueRule<string> {
  const message = customMessage || `Die Zahl darf nicht kleiner als ${formatNumber(min)} sein`;
  return (value) => {
    if (!value) {
      return true;
    }

    return canBeParsedAsNumber(value)
      && convertStringToNumber(value) >= min
      ? true
      : message;
  };
}

export function maxDecimalsNumberStringRule(decimals: number, customMessage?: string): InternalValueRule<string> {
  const message = customMessage ?? decimals > 0
    ? `Die Zahl darf maximal ${formatNumber(decimals)} Nachkommastellen haben`
    : 'Die Zahl darf keine Nachkommastellen haben';

  return (value) => {
    if (!value) {
      return true;
    }
    return canBeParsedAsNumber(value)
      && countDecimals(convertStringToNumber(value)) <= decimals
      ? true
      : message;
  };
}
