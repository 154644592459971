import { render, staticRenderFns } from "./riding-lesson-calendar.vue?vue&type=template&id=48de0edc&scoped=true&"
import script from "./riding-lesson-calendar.vue?vue&type=script&setup=true&lang=ts&"
export * from "./riding-lesson-calendar.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./riding-lesson-calendar.vue?vue&type=style&index=0&id=48de0edc&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48de0edc",
  null
  
)

export default component.exports