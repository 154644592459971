import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { FarmAndUserProperties, FarmAndUserPropertiesForCommandWithFiles } from '@/helpers/default-parameter-helper';
import { FileResponse } from '@/application/types';
import { CSV } from '@/types';
import { CreatePersonAsManagerWorkflow, DefineInvoiceAddressOfPersonAsManagerWorkflow, RemoveInvoiceAddressOfPersonAsManagerWorkflow, UpdateAdditionalMasterDataOfPersonAsManagerCommand, UpdateAddressOfPersonAsManagerCommand, UpdateInvoiceAddressOfPersonAsManagerWorkflow, UpdateNameOfPersonAsManagerWorkflow, GetPersonsAsManagerQuery, GetOrganizationsAsManagerQuery, CreateOrganizationAsManagerWorkflow, UpdateNameOfOrganizationAsManagerWorkflow, UpdateAddressOfOrganizationAsManagerCommand, DefineInvoiceAddressOfOrganizationAsManagerWorkflow, UpdateInvoiceAddressOfOrganizationAsManagerWorkflow, RemoveInvoiceAddressOfOrganizationAsManagerWorkflow, UpdateAdditionalMasterDataOfOrganizationAsManagerCommand, PersonReference, UpdateAssignedPersonsOfOrganizationAsManagerWorkflow, CreateCustomFieldAsManagerCommand, CustomFields, UpdateLabelOfCustomFieldAsManagerCommand, UpdateOptionsOfCustomFieldAsManagerCommand, GetServicesForPersonAsManagerQuery, Service, GetInvoicesForPersonAsManagerQuery, Invoice, GetInvoicePDFAsManagerQuery, UpdateEmailAddressOfPersonAsManagerCommand, UpdateSalutationOfPersonAsManagerCommand, PersonResponse, GetPersonAsManagerQuery, Person, OrganizationResponse, GetOrganizationAsManagerQuery, Organization, GetPersonsAsCSVAsManagerQuery, GetOrganizationsAsCSVAsManagerQuery, ArchivePersonAsManagerWorkflow, ArchiveOrganizationAsManagerWorkflow, GetDocumentsForPersonAsManagerQuery, PersonDocument, CreatePersonDocumentAsManagerCommand, GetDocumentPDFAsManagerQuery, DeletePersonDocumentAsManagerCommand, UpdateCustomFieldShownWithAssignedPersonsAsManagerCommand, DeleteCustomFieldAsManagerCommand, AssignOptionToPersonsAsManagerCommand, OrganizationReference, GetInvoicesForOrganizationAsManagerQuery } from './types';

// -- Queries

export function getPersons(query: GetPersonsAsManagerQuery & FarmAndUserProperties): Promise<PersonResponse> {
  const url = `${apiUrl}/api/customer-master-data/get-persons-as-manager-query`;
  return performApiRequest<PersonResponse>({ url, method: 'POST', data: query });
}

export function getPersonsAsCSV(query: GetPersonsAsCSVAsManagerQuery & FarmAndUserProperties): Promise<CSV> {
  const url = `${apiUrl}/api/customer-master-data/get-persons-as-csv-as-manager-query`;
  return performApiRequest<CSV>({ url, method: 'POST', data: query });
}

export function getPerson(query: GetPersonAsManagerQuery & FarmAndUserProperties): Promise<Person> {
  const url = `${apiUrl}/api/customer-master-data/get-person-as-manager-query`;
  return performApiRequest<Person>({ url, method: 'POST', data: query });
}

export function getOrganizations(query: GetOrganizationsAsManagerQuery & FarmAndUserProperties): Promise<OrganizationResponse> {
  const url = `${apiUrl}/api/customer-master-data/get-organizations-as-manager-query`;
  return performApiRequest<OrganizationResponse>({ url, method: 'POST', data: query });
}

export function getOrganizationsAsCSV(query: GetOrganizationsAsCSVAsManagerQuery & FarmAndUserProperties): Promise<CSV> {
  const url = `${apiUrl}/api/customer-master-data/get-organizations-as-csv-as-manager-query`;
  return performApiRequest<CSV>({ url, method: 'POST', data: query });
}

export function getOrganization(query: GetOrganizationAsManagerQuery & FarmAndUserProperties): Promise<Organization> {
  const url = `${apiUrl}/api/customer-master-data/get-organization-as-manager-query`;
  return performApiRequest<Organization>({ url, method: 'POST', data: query });
}

export function getPersonsForSelection(query: FarmAndUserProperties): Promise<PersonReference[]> {
  const url = `${apiUrl}/api/customer-master-data/get-persons-for-selection-as-manager-query`;
  return performApiRequest<PersonReference[]>({ url, method: 'POST', data: query });
}

export function getOrganizationsForSelection(query: FarmAndUserProperties): Promise<OrganizationReference[]> {
  const url = `${apiUrl}/api/customer-master-data/get-organizations-for-selection-as-manager-query`;
  return performApiRequest<OrganizationReference[]>({ url, method: 'POST', data: query });
}

export function getCustomFields(query: FarmAndUserProperties): Promise<CustomFields> {
  const url = `${apiUrl}/api/customer-master-data/get-custom-fields-as-manager-query`;
  return performApiRequest<CustomFields>({ url, method: 'POST', data: query });
}

export function getServicesForPerson(query: GetServicesForPersonAsManagerQuery & FarmAndUserProperties): Promise<Service[]> {
  const url = `${apiUrl}/api/customer-master-data/get-services-for-person-as-manager-query`;
  return performApiRequest<Service[]>({ url, method: 'POST', data: query });
}

export function getInvoicesForPerson(query: GetInvoicesForPersonAsManagerQuery & FarmAndUserProperties): Promise<Invoice[]> {
  const url = `${apiUrl}/api/customer-master-data/get-invoices-for-person-as-manager-query`;
  return performApiRequest<Invoice[]>({ url, method: 'POST', data: query });
}

export function getInvoicesForOrganization(query: GetInvoicesForOrganizationAsManagerQuery & FarmAndUserProperties): Promise<Invoice[]> {
  const url = `${apiUrl}/api/customer-master-data/get-invoices-for-organization-as-manager-query`;
  return performApiRequest<Invoice[]>({ url, method: 'POST', data: query });
}

export function getInvoicePDF(query: GetInvoicePDFAsManagerQuery & FarmAndUserProperties): Promise<FileResponse> {
  const url = `${apiUrl}/api/ledger/get-invoice-pdf-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

export function getDocumentsForPerson(query: GetDocumentsForPersonAsManagerQuery & FarmAndUserProperties): Promise<PersonDocument[]> {
  const url = `${apiUrl}/api/customer-master-data/get-documents-for-person-as-manager-query`;
  return performApiRequest<PersonDocument[]>({ url, method: 'POST', data: query });
}

export function getDocumentPDF(query: GetDocumentPDFAsManagerQuery & FarmAndUserProperties): Promise<FileResponse> {
  const url = `${apiUrl}/api/customer-master-data/get-document-pdf-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

// -- Commands

export function createPerson(command: CreatePersonAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/create-person-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateSalutationOfPerson(command: UpdateSalutationOfPersonAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-salutation-of-person-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateNameOfPerson(command: UpdateNameOfPersonAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-name-of-person-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateAddressOfPerson(command: UpdateAddressOfPersonAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-address-of-person-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function defineInvoiceAddressOfPerson(
  command: DefineInvoiceAddressOfPersonAsManagerWorkflow & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/define-invoice-address-of-person-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateInvoiceAddressOfPerson(
  command: UpdateInvoiceAddressOfPersonAsManagerWorkflow & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-invoice-address-of-person-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function removeInvoiceAddressOfPerson(
  command: RemoveInvoiceAddressOfPersonAsManagerWorkflow & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/remove-invoice-address-of-person-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateAdditionalMasterDataOfPerson(
  command: UpdateAdditionalMasterDataOfPersonAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-additional-master-data-of-person-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createOrganization(command: CreateOrganizationAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/create-organization-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateNameOfOrganization(command: UpdateNameOfOrganizationAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-name-of-organization-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateAddressOfOrganization(command: UpdateAddressOfOrganizationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-address-of-organization-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function defineInvoiceAddressOfOrganization(
  command: DefineInvoiceAddressOfOrganizationAsManagerWorkflow & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/define-invoice-address-of-organization-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateInvoiceAddressOfOrganization(
  command: UpdateInvoiceAddressOfOrganizationAsManagerWorkflow & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-invoice-address-of-organization-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function removeInvoiceAddressOfOrganization(
  command: RemoveInvoiceAddressOfOrganizationAsManagerWorkflow & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/remove-invoice-address-of-organization-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateAdditionalMasterDataOfOrganization(
  command: UpdateAdditionalMasterDataOfOrganizationAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-additional-master-data-of-organization-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateAssignedPersonsOfOrganization(
  command: UpdateAssignedPersonsOfOrganizationAsManagerWorkflow & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-assigned-persons-of-organization-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createCustomField(command: CreateCustomFieldAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/create-custom-field-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateLabelOfCustomField(command: UpdateLabelOfCustomFieldAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-label-of-custom-field-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateOptionsOfCustomField(command: UpdateOptionsOfCustomFieldAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-options-of-custom-field-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateEmailAddressOfPerson(command: UpdateEmailAddressOfPersonAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-email-address-of-person-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function archivePerson(command: ArchivePersonAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/archive-person-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function archiveOrganization(command: ArchiveOrganizationAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/archive-organization-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createPersonDocument(
  command: CreatePersonDocumentAsManagerCommand & FarmAndUserPropertiesForCommandWithFiles
): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/create-person-document-as-manager-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function deletePersonDocument(command: DeletePersonDocumentAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/delete-person-document-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateCustomFieldShownWithAssignedPersons(
  command: UpdateCustomFieldShownWithAssignedPersonsAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/update-custom-field-shown-with-assigned-persons-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function deleteCustomField(command: DeleteCustomFieldAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/delete-custom-field-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function assignOptionToPersons(command: AssignOptionToPersonsAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/customer-master-data/assign-option-to-persons-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
